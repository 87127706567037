let conteoCurri = 0;

function animationCurri(tit, subtit, parrafo, parte1, parte2) {
    const tit1 = document.querySelector(tit);
    const subtit1 = document.querySelector(subtit);
    const parra1 = document.querySelector(parrafo);
    const part1 = document.querySelector(parte1);
    const part2 = document.querySelector(parte2);

    tit1.style.animation = `appear-c 1s ease 0s 1 normal forwards`;
    subtit1.style.animation = `appear-c 1s ease 0.5s 1 normal forwards`;
    parra1.style.animation = `appear-c 1s ease 1s 1 normal forwards`;
    part1.style.animation = `move-c 3s ease 0s 1 normal forwards`;
    part2.style.animation = `move-c 3s ease 1s 1 normal forwards`;
}

window.addEventListener('scroll', function() {
    const y = window.scrollY;
    const anchoPantalla = document.body.clientWidth;
 
    if(conteoCurri < 1) {
        if(anchoPantalla > 800 && anchoPantalla < 1419) {
            if(y >= 400 && y <= 1800) {
                animationCurri('.curriculo-responsive h2', '.curriculo-responsive h3', '.parrafo-curri', '.img-curri-movil', '.cont-icon-text');
                conteoCurri++;
             } 
        } else if(anchoPantalla > 480 && anchoPantalla < 801){
            if(y >= 500 && y <= 2300) {
                animationCurri('.curriculo-responsive h2', '.curriculo-responsive h3', '.parrafo-curri', '.img-curri-movil', '.cont-icon-text');
                conteoCurri++;
            }
        } else if(anchoPantalla < 481){
            if(y >= 300 && y <= 1800) {
                animationCurri('.curriculo-responsive h2', '.curriculo-responsive h3', '.parrafo-curri', '.img-curri-movil', '.cont-icon-text');
                conteoCurri++;
            }
        }
    }
});